<template>
  <div class="hero">
    <div id="particles"></div>

    <navbar />
    
    <div class="hero-content">
      <h1 class="text-center">Elevate your online<br>presence with<br><span class="devyne-software">Devyne Software</span></h1>
      <h2 class="text-center">I'm here to help you and your project reach more people.</h2>
      <div style="margin-top: 20px; gap: 30px" class="f-row f-ac f-jc">
        <a href="#my-work" @click="update()"><link-button :cta="true">My work</link-button></a>
        <a href="#contact-me" @click="update()"><link-button :cta="false">Contact me</link-button></a>
      </div>
    </div>

    <scroll-indicator class="scroll-indicator"/>
  </div>
</template>

<script>
import ScrollIndicator from '../ScrollIndicator.vue'
import Navbar from '../Navbar.vue'
import LinkButton from '../LinkButton.vue'

import 'particles.js'

export default {
  components: { 
    ScrollIndicator,
    Navbar, 
    LinkButton
  },
  mounted() {
    this.initParticles()
  },
  methods: {
    update() {
      window.history.pushState({},"", '/')
    },
    initParticles() {
      window.particlesJS("particles", {
          "particles": {
              "number": {
                  "value": 150,
                  "density": {
                      "enable": true,
                      "value_area": 700
                  }
              },
              "color": {
                  "value": "#ffffff"
              },
              "shape": {
                  "type": "circle",
                  "stroke": {
                      "width": 0,
                      "color": "#000000"
                  },
                  "polygon": {
                      "nb_sides": 1
                  },
              },
              "opacity": {
                  "value": 1,
                  "random": true,
                  "anim": {
                      "enable": false,
                      "speed": 0.2,
                      "opacity_min": 0.1,
                      "sync": false
                  }
              },
              "size": {
                  "value": 1,
                  "random": true,
                  "anim": {
                      "enable": false,
                      "speed": 40,
                      "size_min": 0.5,
                      "sync": false
                  }
              },
              "line_linked": {
                  "enable": false,
                  "distance": 150,
                  "color": "#ffffff",
                  "opacity": 0.4,
                  "width": 1
              },
              "move": {
                  "enable": true,
                  "speed": 0.2,
                  "direction": "none",
                  "random": false,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                      "enable": false,
                      "rotateX": 600,
                      "rotateY": 1200
                  }
              }
          },
          "interactivity": {
              "detect_on": "canvas",
              "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "bubble"
                  },
                  "onclick": {
                      "enable": false,
                      "mode": "push"
                  },
                  "resize": true
              },
              "modes": {
                  "grab": {
                      "distance": 140,
                      "line_linked": {
                          "opacity": 1
                      }
                  },
                  "bubble": {
                      "distance": 100,
                      "size": 1.5,
                      "duration": 2,
                      "opacity": 1,
                      "speed": 30
                  },
                  "repulse": {
                      "distance": 200,
                      "duration": 0.4
                  },
                  "push": {
                      "particles_nb": 4
                  },
                  "remove": {
                      "particles_nb": 2
                  }
              }
          },
          "retina_detect": true
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/main.scss';

#particles {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0; 
  right: 0;

  @media screen and (max-width: $max-width-mobile) {
    pointer-events: none;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.hero-content {
  position: absolute;
  z-index: 1;
  top: 50%;
  // left: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  pointer-events: none;

  * {
    pointer-events: initial;
  }

  @media screen and (max-width: $max-width-mobile) {
    margin: 0 auto;
    padding: 0 $margin-horizontal-mobile;
  }

  h1 {
    font-size: 60px;
    @media screen and (max-width: $max-width-mobile) {
      font-size: 30px;
      
    }
  }
}

.scroll-indicator {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.devyne-software {
  background: $g-header;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>