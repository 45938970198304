<template>
  <div class="link-button f-row f-ac" :class="{'cta': cta}">
    <slot></slot>
    <div style="position: relative; padding-right: 12px">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="arrow"
      >
        <g clip-path="url(#clip0_69_838)">
          <path
            id="head"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.39913 4.58578L2.8636 1.05025C2.47308 0.659722 1.83991 0.659722 1.44939 1.05025V1.05025C1.05886 1.44077 1.05886 2.07394 1.44939 2.46446L4.27781 5.29289C4.66834 5.68341 4.66834 6.31658 4.27781 6.7071L1.44939 9.53553C1.05886 9.92605 1.05886 10.5592 1.44939 10.9497V10.9497C1.83991 11.3403 2.47308 11.3403 2.8636 10.9497L6.39913 7.41421L7.10624 6.7071C7.49677 6.31658 7.49677 5.68341 7.10624 5.29289L6.39913 4.58578Z"
          />
          <path
            id="tail"
            d="M4.8596 6C4.8596 5.44772 5.30732 5 5.8596 5L6.10727 5L6.10727 7L5.8596 7C5.30732 7 4.8596 6.55228 4.8596 6V6Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_69_838">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0.156387)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cta: false,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.link-button {
  position: relative;
  padding-right: 20px;

  &:hover #tail {
    d: path(

      "M3 6C3 5.44772 3.44772 5 4 5L9 5L9 7L4 7C3.44772 7 3 6.55228 3 6V6Z"
    );
  }
  &:hover #head {
    d: path(
      "M10.2426 4.58578L6.70711 1.05025C6.31658 0.659722 5.68342 0.659722 5.29289 1.05025V1.05025C4.90237 1.44077 4.90237 2.07394 5.29289 2.46446L8.12132 5.29289C8.51184 5.68341 8.51184 6.31658 8.12132 6.7071L5.29289 9.53553C4.90237 9.92605 4.90237 10.5592 5.29289 10.9497V10.9497C5.68342 11.3403 6.31658 11.3403 6.70711 10.9497L10.2426 7.41421L10.9497 6.7071C11.3403 6.31658 11.3403 5.68341 10.9497 5.29289L10.2426 4.58578Z"
    );
  }
}

#tail, #head {
  transition: all 100ms ease;
  -webkit-transition: all 100ms ease;
}

#arrow {
  position: absolute;
  margin-left: 5px;
  top: -5px;
}
</style>
