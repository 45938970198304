import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'


// --------------------- Firebase ---------------------

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyATk4jQ23hDO6w6DEkKIky8JGQnpqEsnp0",
  authDomain: "devyne-software.firebaseapp.com",
  projectId: "devyne-software",
  storageBucket: "devyne-software.appspot.com",
  messagingSenderId: "709933325499",
  appId: "1:709933325499:web:5145590a29412e96929d9e",
  measurementId: "G-0T880WX669"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);

// ------------------- End Firebase -------------------

Vue.config.productionTip = false

Vue.use(VueMeta)

new Vue({
  render: h => h(App),
}).$mount('#app')

