<template>
  <div class="f-col f-ac">
    <p style="font-size: 0.8em; letter-spacing: 0.2em; margin-bottom: 10px">SCROLL</p>
    <svg width="8" height="31" viewBox="0 0 8 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="indicator">
        <circle id="ball" cx="3.5" cy="3.5" r="3.5" fill="white" />
        <rect id="line" x="3" y="2" width="1" height="29" fill="white" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

#ball {
  animation-name: ball;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes ball {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
  
}

</style>