<template>
  <div class="selected-work">
    <div class="sw-display-background">
      <div class="sf-display-floor"></div>
      <div class="image-carousel" :style="{'transform': `translateX(${-index*25}%)`}" @click="nextImage(index + 1)">
        <div>
          <img src="../assets/preview-images/display_blomstra.png">
        </div>
        <div>
          <img src="../assets/preview-images/display_blomstrande_liv.png">
        </div>
        <div>
          <img src="../assets/preview-images/display_runeke_konsult.png">
        </div>
        <div>
          <img src="../assets/preview-images/display_booklake.png">
        </div>
      </div>
    </div>
    <div class="sw-sections-list">
      <div class="sw-section" :style="percentageSCSS">
        <div class="sw-section-marker" :class="{'sw-section-active': index === 0}" @click="nextImage(0)"></div>
        <div class="sw-section-text">
          <p class="sw-section-title text--dark text-bold">Blomstra<span class="sw-status sw-published"></span></p>
          <p class="text--dark">This is an app designed to help marriages and relationships flourish! It features topics for you and your partner to talk about, encourages you to talk about emotions, and provides you with ideas for future date nights.</p>
        </div>
      </div>
      <div class="sw-section" :style="percentageSCSS">
        <div class="sw-section-marker" :class="{'sw-section-active': index === 1}" @click="nextImage(1)"></div>
        <div class="sw-section-text">
          <p class="sw-section-title text--dark text-bold">blomstrandeliv.se<span class="sw-status sw-wip"></span></p>
          <p class="text--dark">A website related to the app Blomstra. This is a blog / news type of website where the admins can create posts that will be listed and searchable for the users.</p>
        </div>
      </div>
      <div class="sw-section" :style="percentageSCSS">
        <div class="sw-section-marker" :class="{'sw-section-active': index === 2}" @click="nextImage(2)"></div>
        <div class="sw-section-text">
          <p class="sw-section-title text--dark text-bold">runekekonsult.se<span class="sw-status sw-published"></span></p>
          <p class="text--dark">This portfolio website provides clients with all of the information they need about Runeke Konsult AB. It is a static site that simply displays all necessary information, such as services, previous work, and contact information.</p>
        </div>
      </div>
      <div class="sw-section" :style="percentageSCSS">
        <div class="sw-section-marker" :class="{'sw-section-active': index === 3}" @click="nextImage(3)"></div>
        <div class="sw-section-text">
          <p class="sw-section-title text--dark text-bold">BookLake<span class="sw-status sw-wip"></span></p>
          <p class="text--dark">BookLake is a an app to inspire and encourage reading. It allows you to keep track of your progress, rate and review books you've read, and see what people around you are reading. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      index: 0,
      timer: 10000,
      maxTimer: 10000,
      timeout: null,
      active: false,
    }
  },
  watch: {
    timer: {
      handler(value) {
        if(this.active) {
          if(value > 0) {
            this.timeout = setTimeout(() => {
              this.timer -= 10
            }, 10)
          } else {
            this.nextImage(this.index + 1)
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    nextImage(index) {
      clearTimeout(this.timeout)

      this.timer = this.maxTimer
      this.index = index
      if(this.index > 3) this.index = 0
    },
    updateScroll() {
      if(window.scrollY > 700) {
        if(!this.active) {
          this.timer = this.maxTimer - 1
        }

        this.active = true
        window.removeEventListener('scroll', this.updateScroll)
      }
    }
  },
  computed: {
    percentageSCSS() {
      return {
        '--current-percentage2': (this.maxTimer - this.timer) / this.maxTimer * 100 + '%'
      }
    }
  }

}
</script>

<style scoped lang="scss">
@import '../styles/main.scss';

$display-width: 30vw;
$display-width-mobile: 60vw;

$current-percentage: 25%;

.selected-work {
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  row-gap: 30px;
  
  @media screen and (max-width: $max-width-tablet) {
    column-gap: 5%; 
  }

  @media screen and (max-width: $max-width-mobile) {
    flex-direction: column;
    align-items: center;
  }
}

.sw-sections-list {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
  row-gap: 30px;

  @media screen and (max-width: $max-width-mobile) {
    row-gap: 30px;
  }
}

.sw-section {
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 30px;

  .sw-section-marker {
    @extend .hoverable;

    position: relative;
    width: 36px;
    height: 53px;
    border-radius: 18px;
    // background: $g-primary;
    transition: background 1s ease;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: $c-dark;
      position: absolute;
      border-radius: 5px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      transition: background 200ms ease;
    }

    &.sw-section-active {
      background: $g-secondary;

      &::after {
        // background-color: $c-light;
        background: conic-gradient(
          rgba(255, 255, 255, 0.575) var(--current-percentage2),
          $c-light var(--current-percentage2)
        );
      }
    }
  }

  .sw-section-text {
    flex: 1;

    .sw-section-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;

        margin-left: 5px;

        &.sw-status {
          position: relative;

          &.sw-published {
            &::after {
              content: 'Status: published';
            }
            background-color: $c-primary-a;
          }
          &.sw-wip {
            &::after {
              content: 'Status: work in progress';
            }
            background-color: $c-primary-b;
          }

          

          &::after {
            // content: 'Status: published';
            pointer-events: none;
            position: absolute;
            width: max-content;
            height: min-content;
            background-color: $c-dark;
            color: $c-light;
            top: 50%;
            left: 100%;
            transform: translate(0px, -50%);
            padding: 5px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: lighter;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

            transition: opacity 200ms ease, transform 200ms ease;
            opacity: 0;
          }
          &:hover {
            &::after {
              opacity: 1;
              transform: translate(10px, -50%);
            }
          }
        }
      }
    }
  }
}

.sw-display-background {
  position: relative;
  width: $display-width;
  max-width: 470px;
  height: 50vw;
  max-height: 790px;
  border-radius: 500px;
  overflow: hidden;
  overflow-x: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  background-color: $c-primary-a;

  @media screen and (max-width: $max-width-mobile) {
    width: $display-width-mobile;
    height: 100vw;
  }
}

.sf-display-floor {
  position: absolute;
  width: 100%;
  height: 20%;
  background: linear-gradient(180deg, #09231F, #2C2C2C);

  bottom: 0;
}

.image-carousel {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  // width: 400%;
  overflow-x: hidden;
  overflow-y: hidden;

  // top: 50%;
  transform: translateX(0%);

  transition: transform 500ms ease;

  div {
    display: flex;
    width: $display-width;
    max-width: 470px;

    @media screen and (max-width: $max-width-mobile) {
      width: $display-width-mobile;
    }

    img {
      max-width: none;
      width: 100%;
      height: auto;
      margin: 0 auto;

    }
  }
}

</style>