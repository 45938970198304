<template>
  <div class="about-me-container">
    <h1>About me</h1>
    <div class="about-me-flex">
      <div class="left-column">
        <img src="../../assets/portrait.jpg">
        <div class="about-me-bio">
          <p style="margin: 30px 0">I do most things related to app and web development. Need help creating a website? Or perhaps you want a mobile app to go along with another project of yours? Contact me, and I can help you and your project reach more people!</p>
          <div class="f-row f-ac" style="column-gap: 10px; padding: 10px">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M28.865 26.5C26.9613 23.2087 24.0275 20.8487 20.6038 19.73C22.2973 18.7218 23.6131 17.1856 24.349 15.3572C25.085 13.5289 25.2004 11.5095 24.6776 9.60918C24.1548 7.70887 23.0226 6.03272 21.455 4.83814C19.8873 3.64355 17.9709 2.99658 16 2.99658C14.0291 2.99658 12.1127 3.64355 10.545 4.83814C8.9774 6.03272 7.84525 7.70887 7.32243 9.60918C6.79961 11.5095 6.91504 13.5289 7.65099 15.3572C8.38694 17.1856 9.70271 18.7218 11.3963 19.73C7.9725 20.8475 5.03875 23.2075 3.135 26.5C3.06519 26.6138 3.01888 26.7405 2.99881 26.8725C2.97875 27.0045 2.98532 27.1392 3.01816 27.2687C3.05099 27.3981 3.10942 27.5197 3.18999 27.6262C3.27056 27.7326 3.37165 27.8219 3.48728 27.8887C3.60292 27.9555 3.73076 27.9985 3.86326 28.015C3.99577 28.0316 4.13025 28.0215 4.25878 27.9853C4.38731 27.949 4.50728 27.8874 4.61161 27.8041C4.71595 27.7207 4.80253 27.6173 4.86625 27.5C7.22125 23.43 11.3838 21 16 21C20.6163 21 24.7788 23.43 27.1338 27.5C27.1975 27.6173 27.2841 27.7207 27.3884 27.8041C27.4927 27.8874 27.6127 27.949 27.7412 27.9853C27.8698 28.0215 28.0042 28.0316 28.1367 28.015C28.2692 27.9985 28.3971 27.9555 28.5127 27.8887C28.6284 27.8219 28.7294 27.7326 28.81 27.6262C28.8906 27.5197 28.949 27.3981 28.9818 27.2687C29.0147 27.1392 29.0213 27.0045 29.0012 26.8725C28.9811 26.7405 28.9348 26.6138 28.865 26.5ZM9 12C9 10.6155 9.41055 9.26214 10.1797 8.11099C10.9489 6.95985 12.0421 6.06264 13.3212 5.53283C14.6003 5.00301 16.0078 4.86439 17.3656 5.13449C18.7235 5.40458 19.9708 6.07127 20.9497 7.05023C21.9287 8.0292 22.5954 9.27648 22.8655 10.6344C23.1356 11.9922 22.997 13.3997 22.4672 14.6788C21.9373 15.9578 21.0401 17.0511 19.889 17.8203C18.7379 18.5894 17.3845 19 16 19C14.1441 18.998 12.3648 18.2599 11.0524 16.9475C9.74012 15.6352 9.00199 13.8559 9 12Z"
                fill="white" />
            </svg>
            <p style="flex: 1">Jonathan Runeke, 23</p>
          </div>
          <div class="f-row f-ac" style="column-gap: 10px; padding: 10px">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M27.3538 12.9713L17.3538 3.53632C17.3489 3.53206 17.3443 3.52746 17.34 3.52257C16.9718 3.18774 16.492 3.0022 15.9944 3.0022C15.4967 3.0022 15.0169 3.18774 14.6488 3.52257L14.635 3.53632L4.64625 12.9713C4.4425 13.1587 4.27985 13.3863 4.16861 13.6398C4.05737 13.8932 3.99996 14.167 4 14.4438V26.0001C4 26.5305 4.21071 27.0392 4.58579 27.4143C4.96086 27.7894 5.46957 28.0001 6 28.0001H12C12.5304 28.0001 13.0391 27.7894 13.4142 27.4143C13.7893 27.0392 14 26.5305 14 26.0001V20.0001H18V26.0001C18 26.5305 18.2107 27.0392 18.5858 27.4143C18.9609 27.7894 19.4696 28.0001 20 28.0001H26C26.5304 28.0001 27.0391 27.7894 27.4142 27.4143C27.7893 27.0392 28 26.5305 28 26.0001V14.4438C28 14.167 27.9426 13.8932 27.8314 13.6398C27.7201 13.3863 27.5575 13.1587 27.3538 12.9713ZM26 26.0001H20V20.0001C20 19.4696 19.7893 18.9609 19.4142 18.5859C19.0391 18.2108 18.5304 18.0001 18 18.0001H14C13.4696 18.0001 12.9609 18.2108 12.5858 18.5859C12.2107 18.9609 12 19.4696 12 20.0001V26.0001H6V14.4438L6.01375 14.4313L16 5.00007L25.9875 14.4288L26.0012 14.4413L26 26.0001Z"
                fill="white" />
            </svg>
            <p style="flex: 1">Lund, Sweden</p>
          </div>
          <div class="f-row f-ac" style="column-gap: 10px; padding: 10px">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31.47 11.1176L16.47 3.11758C16.3253 3.04054 16.1639 3.00024 16 3.00024C15.8361 3.00024 15.6747 3.04054 15.53 3.11758L0.529993 11.1176C0.369998 11.2028 0.236194 11.33 0.142893 11.4854C0.0495918 11.6409 0.000305176 11.8188 0.000305176 12.0001C0.000305176 12.1814 0.0495918 12.3593 0.142893 12.5147C0.236194 12.6702 0.369998 12.7973 0.529993 12.8826L3.99999 14.7338V20.7863C3.99895 21.2775 4.17973 21.7517 4.50749 22.1176C6.14499 23.9413 9.81374 27.0001 16 27.0001C18.0512 27.017 20.087 26.6455 22 25.9051V30.0001C22 30.2653 22.1053 30.5196 22.2929 30.7072C22.4804 30.8947 22.7348 31.0001 23 31.0001C23.2652 31.0001 23.5196 30.8947 23.7071 30.7072C23.8946 30.5196 24 30.2653 24 30.0001V24.9388C25.304 24.1861 26.4824 23.2342 27.4925 22.1176C27.8203 21.7517 28.001 21.2775 28 20.7863V14.7338L31.47 12.8826C31.63 12.7973 31.7638 12.6702 31.8571 12.5147C31.9504 12.3593 31.9997 12.1814 31.9997 12.0001C31.9997 11.8188 31.9504 11.6409 31.8571 11.4854C31.7638 11.33 31.63 11.2028 31.47 11.1176ZM16 25.0001C10.5912 25.0001 7.40999 22.3576 5.99999 20.7863V15.8001L15.53 20.8826C15.6747 20.9596 15.8361 20.9999 16 20.9999C16.1639 20.9999 16.3253 20.9596 16.47 20.8826L22 17.9338V23.7263C20.425 24.4613 18.44 25.0001 16 25.0001ZM26 20.7813C25.4006 21.4465 24.7299 22.0438 24 22.5626V16.8663L26 15.8001V20.7813ZM23.5 14.8676L23.4725 14.8513L16.4725 11.1176C16.2389 10.9983 15.9679 10.9755 15.7177 11.0542C15.4675 11.1328 15.2583 11.3066 15.135 11.5381C15.0117 11.7695 14.9843 12.0402 15.0587 12.2917C15.133 12.5431 15.3032 12.7554 15.5325 12.8826L21.375 16.0001L16 18.8663L3.12499 12.0001L16 5.13383L28.875 12.0001L23.5 14.8676Z"
                fill="white" />
            </svg>
            <p style="flex: 1">Computer Engineering (ML), Lunds Tekniska Högskola</p>
          </div>
        </div>
      </div>
      <div class="right-column">
        <skill img="Figma" text="Figma" />
        <skill img="Git" text="Git" />
        <skill img="Nodejs" text="Node.js" />
        <skill img="HTML5" text="HTML" />
        <skill img="CSS" text="CSS" />
        <skill img="Android" text="Android" />
        <skill img="Java" text="Java" />
        <skill img="Javascript" text="Javascript" />
        <skill img="Flutter" text="Flutter" />
        <skill img="Vue" text="Vue" />
        <skill img="iOS" text="iOS" />
        <skill img="Python" text="Python" />
        <skill img="Dart" text="Dart" />
        <skill img="Tensorflow" text="Tensorflow" />
        <skill img="Firebase" text="Firebase" />
      </div>
    </div>
  </div>
</template>

<script>
import Skill from '../Skill.vue'

export default {
  components: {
    Skill,
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/main.scss';

.about-me-container {
  max-width: 1200px;
  width: 100%;
  padding: 0 $margin-horizontal-mobile;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 8%;
  margin-bottom: 60px;

  .about-me-flex {
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    @media screen and (max-width: $max-width-mobile) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: $max-width-mobile) {
    margin-top: 60px;
  }
}

.left-column {
  display: flex;
  flex-direction: column;

  width: 30vw;
  max-width: 470px;

  img {
    max-width: none;
    width: 100%;
    height: 20vw;
    object-fit: cover;
    border-radius: 8px;

    @media screen and (max-width: $max-width-mobile) {
      height: 60vw;
    }
  }

  .about-me-bio {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    // div {
    //   flex-direction: row;
    // }
  }

  @media screen and (max-width: $max-width-mobile) {
    width: 100%;
    max-width: none;
  }
}

.right-column {
  flex: 1;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;

  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-auto-flow: row;
  grid-column-gap: 1.5vw;
  grid-row-gap: 1.5vw;
  // column-gap: 30px;
  // row-gap: 30px;

  height:min-content;
  margin-left: 60px;


  @media screen and (max-width: $max-width-mobile) {
    margin-left: 0;
    margin-top: 60px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5vw;
    grid-row-gap: 5vw;
  }
}

</style>