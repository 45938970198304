<template>
  <svg
    width="42"
    height="30"
    viewBox="0 0 42 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="logo"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.118 7.91536C23.3166 4.97878 24.3331 1.66309 27.0453 0.281157C27.8889 1.93689 28.5859 3.6566 29.1314 5.42054C29.8365 7.70039 30.2887 10.0541 30.4777 12.439H36.8421C42 12.439 42 11.7022 42 11.7022C42 15.1612 39.1959 17.9654 35.7368 17.9654H30.4431C30.2367 20.2119 29.7967 22.4286 29.1314 24.5795C28.5865 26.3414 27.8905 28.0592 27.0482 29.7131L27.0453 29.7189C24.3329 28.3369 23.3159 25.0211 24.1174 22.0844C24.7455 19.783 25.0674 17.4003 25.0674 15C25.0674 12.5985 24.746 10.2164 24.118 7.91536Z"
      fill="white"
      id="left"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.882 22.0846C18.6834 25.0212 17.6669 28.3369 14.9547 29.7188C14.1111 28.0631 13.4141 26.3434 12.8686 24.5795C12.2033 22.4286 11.7633 20.2119 11.5569 17.9653H5.1579C-1.01809e-06 17.9653 0 18.7022 0 18.7022C-3.024e-07 15.2431 2.80411 12.439 6.26316 12.439L11.5223 12.439C11.7113 10.0541 12.1635 7.70035 12.8686 5.4205C13.4135 3.6586 14.1095 1.94082 14.9518 0.286854L14.9547 0.281118C17.6671 1.66314 18.6841 4.97885 17.8826 7.9156C17.2545 10.217 16.9326 12.5997 16.9326 15C16.9326 17.4014 17.254 19.7836 17.882 22.0846Z"
      fill="white"
      id="right"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
