<template>
  <kinesis-container class="skill-perspective" :perspective="200">
    <kinesis-element :strength="8" type="depth" class="skill-container">
      <img :src="getImage(img)" >
      <p class="text-small">{{ text }}</p>
    </kinesis-element>
  </kinesis-container>
</template>

<script>
import { KinesisContainer, KinesisElement} from 'vue-kinesis'

export default {
  components: {
    KinesisContainer,
    KinesisElement
  },
  props: {
    text: '',
    img: '',
  },
  methods: {
    getImage(url) {
      return require('../assets/tech-logos/Icon=' + url + '.svg')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/main.scss';
.skill-perspective {
  display: flex;
  width: 100%;
  // height: 100%;
  aspect-ratio: 1;
}

.skill-container {
  
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: lighten($c-dark, 6%);
  padding: 10px;

  justify-content: space-between;
  align-items: center;

  border-radius: 12px;

  img {
    pointer-events: none;
    user-select: none;
    width: 70%;
    height: 70%;
  }
 
 @media screen and (max-width: $max-width-mobile) {
    transition: none !important;
    transform: none !important;
  }
}

</style>