var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selected-work"},[_c('div',{staticClass:"sw-display-background"},[_c('div',{staticClass:"sf-display-floor"}),_c('div',{staticClass:"image-carousel",style:({'transform': `translateX(${-_vm.index*25}%)`}),on:{"click":function($event){return _vm.nextImage(_vm.index + 1)}}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"sw-sections-list"},[_c('div',{staticClass:"sw-section",style:(_vm.percentageSCSS)},[_c('div',{staticClass:"sw-section-marker",class:{'sw-section-active': _vm.index === 0},on:{"click":function($event){return _vm.nextImage(0)}}}),_vm._m(4)]),_c('div',{staticClass:"sw-section",style:(_vm.percentageSCSS)},[_c('div',{staticClass:"sw-section-marker",class:{'sw-section-active': _vm.index === 1},on:{"click":function($event){return _vm.nextImage(1)}}}),_vm._m(5)]),_c('div',{staticClass:"sw-section",style:(_vm.percentageSCSS)},[_c('div',{staticClass:"sw-section-marker",class:{'sw-section-active': _vm.index === 2},on:{"click":function($event){return _vm.nextImage(2)}}}),_vm._m(6)]),_c('div',{staticClass:"sw-section",style:(_vm.percentageSCSS)},[_c('div',{staticClass:"sw-section-marker",class:{'sw-section-active': _vm.index === 3},on:{"click":function($event){return _vm.nextImage(3)}}}),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/preview-images/display_blomstra.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/preview-images/display_blomstrande_liv.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/preview-images/display_runeke_konsult.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/preview-images/display_booklake.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sw-section-text"},[_c('p',{staticClass:"sw-section-title text--dark text-bold"},[_vm._v("Blomstra"),_c('span',{staticClass:"sw-status sw-published"})]),_c('p',{staticClass:"text--dark"},[_vm._v("This is an app designed to help marriages and relationships flourish! It features topics for you and your partner to talk about, encourages you to talk about emotions, and provides you with ideas for future date nights.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sw-section-text"},[_c('p',{staticClass:"sw-section-title text--dark text-bold"},[_vm._v("blomstrandeliv.se"),_c('span',{staticClass:"sw-status sw-wip"})]),_c('p',{staticClass:"text--dark"},[_vm._v("A website related to the app Blomstra. This is a blog / news type of website where the admins can create posts that will be listed and searchable for the users.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sw-section-text"},[_c('p',{staticClass:"sw-section-title text--dark text-bold"},[_vm._v("runekekonsult.se"),_c('span',{staticClass:"sw-status sw-published"})]),_c('p',{staticClass:"text--dark"},[_vm._v("This portfolio website provides clients with all of the information they need about Runeke Konsult AB. It is a static site that simply displays all necessary information, such as services, previous work, and contact information.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sw-section-text"},[_c('p',{staticClass:"sw-section-title text--dark text-bold"},[_vm._v("BookLake"),_c('span',{staticClass:"sw-status sw-wip"})]),_c('p',{staticClass:"text--dark"},[_vm._v("BookLake is a an app to inspire and encourage reading. It allows you to keep track of your progress, rate and review books you've read, and see what people around you are reading. ")])])
}]

export { render, staticRenderFns }