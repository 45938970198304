<template>
  <div class="selected-work-expansion" :style="{'scale': `${targetWidth}`}">
    <div class="selected-work-backdrop">
      <div class="selected-work-container">
        <h1 class="text--dark" style="margin-bottom: 50px">Selected work</h1>
        <div style="padding-bottom: 50px">
          <selected-work-display />
        </div>
      </div>
    </div>
    <svg width="1920" height="275" viewBox="0 0 1920 275" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1920 274.977L1813.33 257.53C1706.67 240.172 1493.33 205.278 1280 207.492C1066.67 209.706 853.333 249.028 640 260.364C426.667 271.7 213.333 255.227 106.667 246.902L-1.13791e-05 238.666L0 0.429855L106.667 0.429859C213.333 0.429863 426.667 0.429872 640 0.42988C853.333 0.429889 1066.67 0.429898 1280 0.429906C1493.33 0.429915 1706.67 0.429923 1813.33 0.429927L1920 0.429932L1920 274.977Z"
        fill="white" />
    </svg>
  </div>
</template>

<script>
import SelectedWorkDisplay from '../SelectedWorkDisplay.vue';

export default {
  components: { 
    SelectedWorkDisplay,
  },
  data() {
    return {
      active: true,
      targetWidth: 0.9,
    }
  },
  methods: {
    updateScroll() {
      // console.log(window.scrollY)
      // this.targetWidth = 80 + window.scrollY / 500.0 * 20
      // if(this.targetWidth > 100) this.targetWidth = 100;

      this.targetWidth = 1 - ((500 - window.scrollY) / 500 * 0.1)
      if(this.targetWidth > 1) this.targetWidth = 1

      // this.active = window.scrollY < 771
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/main.scss';

.selected-work-expansion {
  // z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;

  
  transition: scale 1000ms ease;

  box-sizing: border-box;
  
  width: 100%;
  height: 100%;
  
  margin: 0 auto;
  margin-bottom: 5%;
  

  svg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    transform: translateY(30%);
  }

  @media screen and (max-width: $max-width-mobile) {
    padding-top: 20px;
  }
}

.selected-work-backdrop {
  background-color: $c-light;
  padding-top: 60px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.selected-work-container {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  width: 100%;
  padding: 0 $margin-horizontal-mobile;
  box-sizing: border-box;
  margin: 0 auto;
  
  // padding-top: 60px;
}

</style>