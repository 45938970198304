
import Vue from 'vue';
import HelloWorld from './components/HelloWorld.vue';
import AboutMe from './components/sections/AboutMe.vue';
import ContactMe from './components/sections/ContactMe.vue';
import NewHero from './components/sections/Hero.vue';
import SelectedWork from './components/sections/SelectedWork.vue';

export default Vue.extend({
  name: 'App',
  metaInfo: {
    title: 'Devyne Software',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'I do most things related to app and web development. Need help creating a website? Or perhaps you want a mobile app to go along with another project of yours? Contact me, and I can help you and your project reach more people!' },
      { name: 'keywords', content: 'Devyne Software, App, Web, Development, Design, Portfolio' },
      { name: 'author', content: 'Jonathan Runeke' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Devyne Software' },
      { property: 'og:description', content: 'I do most things related to app and web development. Need help creating a website? Or perhaps you want a mobile app to go along with another project of yours? Contact me, and I can help you and your project reach more people!' },
      // { property: 'og:image', content: 'https://devynesoftware.com/assets/og-image.png' },
      { property: 'og:url', content: 'https://devyne.se' },
      { property: 'og:site_name', content: 'Devyne Software' },
      { property: 'og:locale', content: 'en' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: 'Devyne Software' },
      { name: 'twitter:description', content: 'I do most things related to app and web development. Need help creating a website? Or perhaps you want a mobile app to go along with another project of yours? Contact me, and I can help you and your project reach more people!' },
    ],
  },
  components: {
    HelloWorld,
    NewHero,
    SelectedWork,
    AboutMe,
    ContactMe,
  },
  methods: {
    scrollToRef(ref: String) {
      let el
      if(ref == '#about-me') {
        el = (this.$refs.aboutMe as Vue & { $el: () => any }).$el
      } else if(ref == '#my-work') {
        el = (this.$refs.myWork as Vue & { $el: () => any }).$el
      } else if(ref == '#contact-me') {
        el = (this.$refs.contactMe as Vue & { $el: () => any }).$el
      }


      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
  },
  mounted() {
    if(window.location.hash) {
      this.scrollToRef(window.location.hash)
    }

    window.addEventListener('hashchange', () => {
      if(window.location.hash) {
        this.scrollToRef(window.location.hash)
      }
      console.log('Current hash: ' + window.location.hash)
    })
  },
  updated() {
    if(window.location.hash) {
      this.scrollToRef(window.location.hash)
    }
  }
})
